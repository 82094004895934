* {
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
}

html, body {
  height: 100%;
}
